*{
    margin: 0; //reset rules
    padding: 0; //reset rules
    box-sizing: border-box; //helps with adding boarder or padding.
      font-family: 'Poppins', sans-serif;
  }
  
html {
    font-size: 100%; //defaults to 16px
}

*, 
*::before, 
*::after {
    box-sizing: inherit;
    
}

body {
 display: flex;
 width: 100%;
 align-items: center;
}


