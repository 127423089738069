:root {
    --background: #5e718b;
    --highlight: #b4bfc5;
    --section: #96aa9a;
    --text1:#EEECE1;
    --background2: #FFF;
    --text2:#00000fff;
    --button:#275EFE;
    // socials
    --social-bg:#ffffff;
    --facebook:#3b5998;
    --twitter:#5da9dd;
    --youtube:#fe0108;
    --github:#1b1f23;
    --linkedin:#0077b5;
    --free-code:#1E3050;
    --codepen:#1E3050;
    --social-logo:ffffff;

}