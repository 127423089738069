.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background: var(--background);
    color: var(--text1);
}

// project cards

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 100px 50px;
    padding: 100px 50px;
}

.container .card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 350px;
    max-width: 100%;
    height: 300px;
    background: var(--section);
    border-radius: 20px;
    transition: 0.5s;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.container .card:hover {
    height: 400px;
    cursor: pointer;
}

.container .card .img-box {
    position: absolute;
    top: 20px;
    width: 300px;
    height: 220px;
    background: var(--highlight);
    border-radius: 12px;
    overflow: hidden;
    transition: 0.5s;
}

.container .card:hover .img-box {
    top: -100px;
    scale: 0.75;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.container .card .img-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container .card .content {
    position: absolute;
    top: 252px;
    width: 100%;
    height: 35px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
}

.container .card:hover .content {
    top: 130px;
    height: 250px;
}

.container .card .content h2 {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--clr);
}

.container .card .content p {
    color: var(--text1);
}

.container .card .content a {
    position: relative;
    top: 15px;
    display: inline-block;
    padding: 20px 20px;
    text-decoration: none;
    background: var(--button);
    color: var(--text1);
    font-weight: 500;
    border-radius: 5%;
}

.container .card .content a:hover {
    opacity: 0.8;
}

@media (max-width: 480px) {
    .container .card {
        width: 230px;
        border-radius: 15px;
    }

    .container .card .img-box {
        width: 185px;
        border-radius: 10px;
    }

    .container .card .content p {
        font-size: 0.8rem;
    }

    .container .card .content a {
        font-size: 0.9rem;
    }
}