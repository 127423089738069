:root {
    --sectionHeigth: 100vh;
}

h1,
h2,
h3 {
    font-weight: 700;
    line-height: 1.1;
    margin-top: 0;
}

p {
    margin-top: 0;
}

a,
a:visited,
a:active {
    text-decoration: none;
}