*:focus,
*:active {
    
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.wrapper {
    display: flex;
  }
  
  .wrapper .icon-container {
    // 
    position: relative;
    width: 90px;
    height: 90px;
    background-color: var(--social-bg);
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    border-radius: 28%;
    box-shadow: 0 5px 15px -5px rgb(0 0 0 / 30%);
    cursor: pointer;
    font-size: 36px;
    text-decoration: none;
    opacity: 0.99;
    overflow: hidden;
  }
  
  .wrapper .icon-container::before {
    content: "";
    width: 120%;
    height: 120%;
    position: absolute;
    top: 90%;
    left: -110%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all 0.35s;
    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
    z-index: 1;
  }
  
  .wrapper .icon-container .icon {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition: all 0.35s;
    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
    z-index: 2;
  }
  
  .wrapper .icon-container.facebook {
    color: var(--facebook);
  }
  
  .wrapper .icon-container.twitter {
    color: var(--twitter);
  }
  
  .wrapper .icon-container.youtube {
    color: var(--youtube);
  }
  
  .wrapper .icon-container.github {
    color: var(--github);
  }
  
  .wrapper .icon-container.linkedin {
    color: var(--linkedin);
  }
  
  .wrapper .icon-container:hover .icon {
    color: #ffffff;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  .wrapper .icon-container:hover::before {
    top: -10%;
    left: -10%;
  }
  
  .wrapper .icon-container.facebook:hover::before {
    background-color: var(--facebook);
  }
  
  .wrapper .icon-container.twitter:hover::before {
    background-color: var(--twitter);
  }
  
  .wrapper .icon-container.youtube:hover::before {
    background-color: var(--youtube)
  }
  
  .wrapper .icon-container.github:hover::before {
    background-color: var(--github);
  }
  
  .wrapper .icon-container.linkedin:hover::before {
    background-color: var(--linkedin);
  }

  .wrapper .icon-container.free-code-camp:hover::before {
    background-color: var(--free-code);
  }

  .wrapper .icon-container.codepen:hover::before {
    background-color: var(--codepen);
  }
  
  @media screen and (max-width: 768px) {
    .wrapper .icon-container {
      width: 45px;
      height: 45px;
      margin: 0 5px;
      font-size: 18px;
    }
  }