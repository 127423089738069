
 
  ul, li, h1, h2, h3, h4, h5, h6, p, a, .logo {
    font-family: "Lato","Verdana",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  
  .inner {
    width: 800px;
    position: relative;
    margin: 0 auto;
  }
  
  li {
    list-style: none;
  }
  
  nav {
    overflow: hidden;
    background: var(--background);
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 0;
    width: 100%;
    z-index: 2;
  }
  
  nav ul {
    float: right;
  }
  
  nav li {
    display: inline;
    float: left;
  }
  
  nav a {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    padding: 25px;
    transition: 0.3s all;
  }
  
  nav a:hover {
    background: var(--section);
  }
  
  
  
  section .inner {
    padding-top: 200px;
  }

  @media (max-width: 480px) {
    nav a{
        padding:10px
    }
  }  