.introduction {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--background);
    color: var(--text1);
    min-height: var(--sectionHeigth);
}

p {
    justify-content: center;
}

.profile {
    border-radius: 5%;
}

@media (max-width: 480px) {

    .introduction{
        flex-wrap: wrap;
    }
    
}