@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
:root {
  --background: #5e718b;
  --highlight: #b4bfc5;
  --section: #96aa9a;
  --text1:#EEECE1;
  --background2: #FFF;
  --text2:#00000fff;
  --button:#275EFE;
  --social-bg:#ffffff;
  --facebook:#3b5998;
  --twitter:#5da9dd;
  --youtube:#fe0108;
  --github:#1b1f23;
  --linkedin:#0077b5;
  --free-code:#1E3050;
  --codepen:#1E3050;
  --social-logo:ffffff;
}

:root {
  cursor: url("../images/cursor/gold.png"), auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: flex;
  width: 100%;
  align-items: center;
}

:root {
  --sectionHeigth: 100vh;
}

h1,
h2,
h3 {
  font-weight: 700;
  line-height: 1.1;
  margin-top: 0;
}

p {
  margin-top: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

ul, li, h1, h2, h3, h4, h5, h6, p, a, .logo {
  font-family: "Lato", "Verdana", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.inner {
  width: 800px;
  position: relative;
  margin: 0 auto;
}

li {
  list-style: none;
}

nav {
  overflow: hidden;
  background: var(--background);
  position: fixed;
  top: 0;
  right: 0;
  padding-top: 0;
  width: 100%;
  z-index: 2;
}

nav ul {
  float: right;
}

nav li {
  display: inline;
  float: left;
}

nav a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 25px;
  transition: 0.3s all;
}

nav a:hover {
  background: var(--section);
}

section .inner {
  padding-top: 200px;
}

@media (max-width: 480px) {
  nav a {
    padding: 10px;
  }
}
.introduction {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: var(--background);
  color: var(--text1);
  min-height: var(--sectionHeigth);
}

p {
  justify-content: center;
}

.profile {
  border-radius: 5%;
}

@media (max-width: 480px) {
  .introduction {
    flex-wrap: wrap;
  }
}
html {
  -webkit-font-smoothing: antialiased;
}

.resume {
  min-height: var(--sectionHeight);
  background: var(--background);
  color: var(--text1);
}

.textSphere {
  display: flex;
  justify-content: center;
  align-items: center;
}

.certs {
  display: flex;
  justify-content: center;
}

.certs img {
  width: 150px;
}

.dlcontainer {
  width: 240px;
  height: 200px;
  margin: auto;
  cursor: pointer;
}

.download {
  font-size: 20px;
  color: #fff;
  background: var(--section);
  padding: 20px;
  width: 200px;
  transition: 1s ease;
  margin: 30px 0 0 0;
}

.dlbutton {
  margin-left: 50px;
}

.dlcontainer:hover .download {
  transform: rotate(360deg);
}

.download:active {
  background: #25953E;
}

.load {
  background: transparent;
  background: RGBa(0, 0, 0, 0.2);
  width: 200px;
  height: 10px;
  margin: 10px auto;
  display: none;
}

.inner {
  width: 5%;
  height: 10px;
  border-radius: 3px;
  background: #333;
  transition: 0.25s ease, 7s background;
}

.about {
  display: block;
  padding: 0 20px;
  background: var(--background);
  color: var(--text1);
  min-height: var(--sectionHeigth);
}

.about-info {
  display: flex;
  justify-content: space-around;
}

.aboutme-info {
  max-width: 1600px;
  margin: 0 auto;
  font-size: larger;
  line-height: 4vh;
}

.sectioncontainder {
  max-width: 1600px;
  margin: 0 50px;
  padding: 5px 50px 50px 50px;
  background: var(--section);
  border-radius: 20px;
}

.profile3d {
  border-radius: 15%;
  width: 300px;
  padding-right: 25px;
}

@media (max-width: 480px) {
  .about-info {
    flex-wrap: wrap;
  }
  .aboutme-info {
    line-height: 2.2vh;
  }
}
.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background: var(--background);
  color: var(--text1);
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px 50px;
  padding: 100px 50px;
}

.container .card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 350px;
  max-width: 100%;
  height: 300px;
  background: var(--section);
  border-radius: 20px;
  transition: 0.5s;
  box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.container .card:hover {
  height: 400px;
  cursor: pointer;
}

.container .card .img-box {
  position: absolute;
  top: 20px;
  width: 300px;
  height: 220px;
  background: var(--highlight);
  border-radius: 12px;
  overflow: hidden;
  transition: 0.5s;
}

.container .card:hover .img-box {
  top: -100px;
  scale: 0.75;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.container .card .img-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container .card .content {
  position: absolute;
  top: 252px;
  width: 100%;
  height: 35px;
  padding: 0 30px;
  text-align: center;
  overflow: hidden;
  transition: 0.5s;
}

.container .card:hover .content {
  top: 130px;
  height: 250px;
}

.container .card .content h2 {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--clr);
}

.container .card .content p {
  color: var(--text1);
}

.container .card .content a {
  position: relative;
  top: 15px;
  display: inline-block;
  padding: 20px 20px;
  text-decoration: none;
  background: var(--button);
  color: var(--text1);
  font-weight: 500;
  border-radius: 5%;
}

.container .card .content a:hover {
  opacity: 0.8;
}

@media (max-width: 480px) {
  .container .card {
    width: 230px;
    border-radius: 15px;
  }
  .container .card .img-box {
    width: 185px;
    border-radius: 10px;
  }
  .container .card .content p {
    font-size: 0.8rem;
  }
  .container .card .content a {
    font-size: 0.9rem;
  }
}
.contact {
  background: var(--background);
  color: var(--text1);
  min-height: var(--sectionHeigth);
}

.section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.contactsection {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contactbox {
  background: var(--section);
  padding: 30px;
  border-radius: 20px;
}

.wrapper {
  padding: 10px 0 50px 0;
}

#qrcode {
  width: 200px;
  height: 200px;
  margin: 0 10px;
}

textarea {
  width: 400px;
  height: 400px;
}

.button {
  --primary: #275EFE;
  --primary-dark: #2055EE;
  --primary-darkest: #133FC0;
  --text: #FFFFFF;
  --text-opacity: 1;
  --success: #2B3044;
  --success-scale: .2;
  --success-opacity: 0;
  --border-radius: 7;
  --overflow: hidden;
  --rotate: 0;
  --plane-x: 0;
  --plane-y: 0;
  --plane-opacity: 1;
  --left-wing-background: var(--primary);
  --left-wing-first-x: 0;
  --left-wing-first-y: 0;
  --left-wing-second-x: 50;
  --left-wing-second-y: 0;
  --left-wing-third-x: 0;
  --left-wing-third-y: 100;
  --left-body-background: var(--primary);
  --left-body-first-x: 50;
  --left-body-first-y: 0;
  --left-body-second-x: 50;
  --left-body-second-y: 100;
  --left-body-third-x: 0;
  --left-body-third-y: 100;
  --right-wing-background: var(--primary);
  --right-wing-first-x: 50;
  --right-wing-first-y: 0;
  --right-wing-second-x: 100;
  --right-wing-second-y: 0;
  --right-wing-third-x: 100;
  --right-wing-third-y: 100;
  --right-body-background: var(--primary);
  --right-body-first-x: 50;
  --right-body-first-y: 0;
  --right-body-second-x: 50;
  --right-body-second-y: 100;
  --right-body-third-x: 100;
  --right-body-third-y: 100;
  display: block;
  cursor: pointer;
  position: relative;
  border: 0;
  padding: 8px 0;
  min-width: 100px;
  text-align: center;
  margin: 0;
  line-height: 24px;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  background: none;
  outline: none;
  color: var(--text);
  transform: rotate(calc(var(--rotate) * 1deg)) translateZ(0);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.button .left,
.button .right {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: var(--plane-opacity);
  transform: translate(calc(var(--plane-x) * 1px), calc(var(--plane-y) * 1px)) translateZ(0);
}
.button .left:before, .button .left:after,
.button .right:before,
.button .right:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: calc(var(--border-radius) * 1px);
  transform: translate(var(--x, 0.4%), var(--y, 0)) translateZ(0);
  z-index: var(--z-index, 2);
  background: var(--left-wing-background);
  clip-path: polygon(calc(var(--first-x, var(--left-wing-first-x)) * 1%) calc(var(--first-y, var(--left-wing-first-y)) * 1%), calc(var(--second-x, var(--left-wing-second-x)) * 1%) calc(var(--second-y, var(--left-wing-second-y)) * 1%), calc(var(--third-x, var(--left-wing-third-x)) * 1%) calc(var(--third-y, var(--left-wing-third-y)) * 1%));
}
.button .left:after {
  --x: 0;
  --z-index: 1;
  --background: var(--left-body-background);
  --first-x: var(--left-body-first-x);
  --first-y: var(--left-body-first-y);
  --second-x: var(--left-body-second-x);
  --second-y: var(--left-body-second-y);
  --third-x: var(--left-body-third-x);
  --third-y: var(--left-body-third-y);
}
.button .right:before {
  --x: -.4%;
  --z-index: 2;
  --background: var(--right-wing-background);
  --first-x: var(--right-wing-first-x);
  --first-y: var(--right-wing-first-y);
  --second-x: var(--right-wing-second-x);
  --second-y: var(--right-wing-second-y);
  --third-x: var(--right-wing-third-x);
  --third-y: var(--right-wing-third-y);
}
.button .right:after {
  --x: 0;
  --z-index: 1;
  --background: var(--right-body-background);
  --first-x: var(--right-body-first-x);
  --first-y: var(--right-body-first-y);
  --second-x: var(--right-body-second-x);
  --second-y: var(--right-body-second-y);
  --third-x: var(--right-body-third-x);
  --third-y: var(--right-body-third-y);
}
.button span {
  display: block;
  position: relative;
  z-index: 4;
  opacity: var(--text-opacity);
}
.button span.success {
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 8px;
  transform: rotate(calc(var(--rotate) * -1deg)) scale(var(--success-scale)) translateZ(0);
  opacity: var(--success-opacity);
  color: var(--success);
}

@media (max-width: 480px) {
  .contactsection {
    display: flex;
    flex-wrap: wrap;
  }
  .contact {
    height: auto;
  }
  textarea {
    width: 300px;
  }
  .sociallinks {
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }
}
*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.wrapper {
  display: flex;
}

.wrapper .icon-container {
  position: relative;
  width: 90px;
  height: 90px;
  background-color: var(--social-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border-radius: 28%;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 36px;
  text-decoration: none;
  opacity: 0.99;
  overflow: hidden;
}

.wrapper .icon-container::before {
  content: "";
  width: 120%;
  height: 120%;
  position: absolute;
  top: 90%;
  left: -110%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
  z-index: 1;
}

.wrapper .icon-container .icon {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
  z-index: 2;
}

.wrapper .icon-container.facebook {
  color: var(--facebook);
}

.wrapper .icon-container.twitter {
  color: var(--twitter);
}

.wrapper .icon-container.youtube {
  color: var(--youtube);
}

.wrapper .icon-container.github {
  color: var(--github);
}

.wrapper .icon-container.linkedin {
  color: var(--linkedin);
}

.wrapper .icon-container:hover .icon {
  color: #ffffff;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.wrapper .icon-container:hover::before {
  top: -10%;
  left: -10%;
}

.wrapper .icon-container.facebook:hover::before {
  background-color: var(--facebook);
}

.wrapper .icon-container.twitter:hover::before {
  background-color: var(--twitter);
}

.wrapper .icon-container.youtube:hover::before {
  background-color: var(--youtube);
}

.wrapper .icon-container.github:hover::before {
  background-color: var(--github);
}

.wrapper .icon-container.linkedin:hover::before {
  background-color: var(--linkedin);
}

.wrapper .icon-container.free-code-camp:hover::before {
  background-color: var(--free-code);
}

.wrapper .icon-container.codepen:hover::before {
  background-color: var(--codepen);
}

@media screen and (max-width: 768px) {
  .wrapper .icon-container {
    width: 45px;
    height: 45px;
    margin: 0 5px;
    font-size: 18px;
  }
}